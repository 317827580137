<template>
  <div>
    <v-img src="./../../assets/images/profile.jpg" height="800px">
      <v-layout wrap justify-center>
        <v-flex xs10>
          <v-layout wrap justify-start pt-5>
            <v-flex xs2 sm1 text-left>
              <v-img
                height="40px"
                width="40px"
                contain
                src="./../../assets/icons/profile.png"
              ></v-img>
            </v-flex>
            <v-flex xs10 sm10 text-left>
              <v-layout wrap>
                <v-flex xs12 pb-2>
                  <v-layout wrap>
                    <v-flex xs6 sm3>
                      <span
                        style="
                          font-family: scopeOneRegular;
                          color: #000;
                          font-size: 18px;
                        "
                        >{{ user.name }}</span
                      >
                    </v-flex>
                    <v-flex xs5 sm5>
                      <v-btn rounded small dark depressed color="#7CB9E8">
                        <span
                          style="font-family: montserratMedium; font-size: 12px"
                          >EDIT</span
                        >
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratRegular;
                      color: #929292;
                      font-size: 13px;
                    "
                    >Email</span
                  >
                </v-flex>
                <v-flex xs12 pb-2>
                  <span
                    style="
                      font-family: scopeOneRegular;
                      color: #000;
                      font-size: 18px;
                    "
                    >{{ user.email }}</span
                  >
                </v-flex>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratRegular;
                      color: #929292;
                      font-size: 13px;
                    "
                    >User ID</span
                  >
                </v-flex>
                <v-flex xs12 pb-2>
                  <span
                    style="
                      font-family: scopeOneRegular;
                      color: #000;
                      font-size: 18px;
                    "
                    >{{ user.userID }}</span
                  >
                </v-flex>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratRegular;
                      color: #929292;
                      font-size: 13px;
                    "
                    >Organization name</span
                  >
                </v-flex>
                <v-flex xs12 pb-2>
                  <span
                    style="
                      font-family: scopeOneRegular;
                      color: #000;
                      font-size: 18px;
                    "
                    >{{ user.organisationName }}</span
                  >
                </v-flex>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratRegular;
                      color: #929292;
                      font-size: 13px;
                    "
                    >Identity number</span
                  >
                </v-flex>
                <v-flex xs12 pb-2>
                  <span
                    style="
                      font-family: scopeOneRegular;
                      color: #000;
                      font-size: 18px;
                    "
                    >{{ user.identityNumber }}</span
                  >
                </v-flex>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratRegular;
                      color: #929292;
                      font-size: 13px;
                    "
                    >Identity verification files</span
                  >
                </v-flex>
                <v-flex xs12 sm12 pb-2 pt-2>
                  <v-chip
                    v-for="(list, index) in user.identityProof"
                    :key="index"
                    class="px-2 grey--text bg"
                    color="white"
                  >
                    <span v-if="list">
                      {{ list }}
                      &nbsp;
                      <span
                        v-if="
                          list.split('.').pop() == 'pdf' ||
                          list.split('.').pop() == 'docx'
                        "
                      >
                        <a target="_blank" :href="mediaUURL + list">
                          <v-btn dark rounded outlined x-small> View </v-btn></a
                        ></span
                      >
                      <span v-else>
                        <v-btn
                          @click="(doc = list), (dialog = true)"
                          dark
                          rounded
                          color="#7CB9E8"
                          x-small
                        >
                          View
                        </v-btn>
                      </span>
                    </span>
                  </v-chip>
                </v-flex>
                <v-flex xs12 pt-3>
                  <v-btn
                    rounded
                    dark
                    to="/assetList"
                    :ripple="true"
                    depressed
                    color="#7CB9E8"
                  >
                    <span
                      style="
                        font-family: montserratRegular;
                        font-size: 12px;
                        text-transform: none;
                      "
                      >View Transactions</span
                    >
                  </v-btn>
                </v-flex>
                <v-dialog persistent v-model="dialog" width="70%">
                  <v-card>
                    <v-toolbar dense dark color="#7CB9E8">
                      <v-spacer></v-spacer>
                      <v-btn
                        right
                        icon
                        dark
                        @click="(doc = null), (dialog = false)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-title class="text-h5 grey lighten-2">
                      Document
                    </v-card-title>
                    <v-card-text v-if="doc">
                      <v-img :src="mediaUURL + doc"></v-img>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        small
                        color="red"
                        text
                        @click="(dialog = false), (doc = null)"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-img>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    //
    appLoading: false,
    ServerError: false,
    showSnackBar: false,
    msg: null,
    timeout: 5000,
    user: [],
    docs: [],
    doc: null,
    dialog: false,
  }),
  beforeMount() {
    if (typeof localStorage.getItem("token") == "string") {
      this.getProfile();
    }
  },
  methods: {
    getProfile() {
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.user = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.bg {
  border-style: solid;
  border-color: green;
}
</style>
